.container__slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: whitesmoke;
  font-size: 24px;
}

.container__slider > button:active {
  color: black;
}

.container__slider > button:hover {
  cursor: pointer;
}

.slider__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__item-active-1 {
  opacity: 1;
  z-index: 2;
}

.container__slider__links {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 3;
}

.container__slider__links-small {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 0;
}

.container__slider__links-small-active {
  background-color: #fff;
}

.slider__btn-next,
.slider__btn-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: all 0.2s ease;
}

.slider__btn-next {
  right: 20px;
}

.slider__btn-prev {
  left: 20px;
}

.slider__btn-next:hover,
.slider__btn-prev:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transform: translateY(-50%) scale(1.1);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .slider__btn-next,
  .slider__btn-prev {
    width: 32px;
    height: 32px;
  }

  .container__slider__links {
    bottom: 16px;
  }

  .container__slider__links-small {
    width: 6px;
    height: 6px;
  }
}
