/* EventPost.css */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.event-post {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
  height: 100vh;
  position: relative;
}

@media (min-width: 768px) {
  .event-post {
    flex-direction: row;
  }
}

/* Left Section: Image Carousel */
.event-post__image-section {
  flex: 1;
  position: relative;
  background-color: #000;
  height: 40vh;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .event-post__image-section {
    flex: 2;
    height: 100vh;
  }
}

.carousel {
  position: relative;
  height: 100%;
  width: 100%;
}

.carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.carousel__image:hover {
  transform: scale(1.02);
}

/* Right Section: Event Details */
.event-post__details-section {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFD;
  gap: 12px;
  overflow-y: auto;
  height: 60vh;
  scroll-behavior: smooth;
}

.event-post__details-section::-webkit-scrollbar {
  width: 6px;
}

.event-post__details-section::-webkit-scrollbar-track {
  background: #F9FAFB;
  border-radius: 3px;
}

.event-post__details-section::-webkit-scrollbar-thumb {
  background: #E4E7EC;
  border-radius: 3px;
  transition: background 0.3s ease;
}

.event-post__details-section::-webkit-scrollbar-thumb:hover {
  background: #D0D5DD;
}

@media (min-width: 768px) {
  .event-post__details-section {
    height: 100vh;
    padding: 24px;
  }
}

.event-post__title {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: #101828;
  margin: 0 0 16px 0;
  letter-spacing: -0.02em;
}

.event-post__author {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.event-post__author:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.12);
}

.event-post__author-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 4px rgba(16, 24, 40, 0.06);
  transition: transform 0.2s ease;
}

.event-post__author-avatar:hover {
  transform: scale(1.05);
}

.event-post__author-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.event-post__author-name {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #101828;
}

.event-post__author-followers {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}

.event-post__description {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: #475467;
  line-height: 1.6;
  margin: 0;
  padding: 0 16px;
}

.event-post__tags {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: #175CD3;
  transform: skewX(-10deg);
  margin: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.event-post__info {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: #101828;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.event-post__info p {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;
  padding: 8px 0;
  border-bottom: 1px solid #F2F4F7;
}

.event-post__info p:last-child {
  border-bottom: none;
}

.event-post__join-button {
  background: linear-gradient(135deg, #02CEFA -1.68%, #2763FF 48.87%, #A515FD 99.43%);
  color: white;
  border: none;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin: 16px 0;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.event-post__join-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: 0.5s;
}

.event-post__join-button:hover::before {
  left: 100%;
}

.event-post__join-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

/* Comments Section */
.event-post__comments {
  margin: 16px 0;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.comment_input {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  align-items: center;
}

.comment_input_bar {
  flex: 1;
  position: relative;
}

.comment_input_text {
  width: 100%;
  height: 44px;
  padding: 12px 44px 12px 16px;
  border: 1px solid #E4E7EC;
  border-radius: 24px;
  background-color: #F9FAFB;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  outline: none;
  transition: all 0.2s ease;
}

.comment_input_text:focus {
  border-color: #2763FF;
  box-shadow: 0 0 0 4px rgba(39, 99, 255, 0.1);
}

.comment_input_button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #2763FF;
  transition: transform 0.2s ease;
}

.comment_input_button:hover {
  transform: translateY(-50%) scale(1.1);
}

.comment_input_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 4px rgba(16, 24, 40, 0.06);
}

.comment-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comment-container {
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

.comment-container:hover {
  background-color: #F9FAFB;
}

.comment-content {
  flex: 1;
}

.username {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  margin-bottom: 4px;
}

.comment-text {
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  color: #475467;
  margin: 8px 0;
  line-height: 1.5;
}

.comment-time {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  color: #667085;
  margin-top: 6px;
}

.comment-actions {
  display: flex;
  gap: 16px;
  margin-top: 12px;
}

.comment-like {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 16px;
  transition: background-color 0.2s ease;
}

.comment-like:hover {
  background-color: #F2F4F7;
}

.like-count {
  font-family: 'Rubik', sans-serif;
  font-size: 13px;
  color: #475467;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  border-radius: 24px;
  padding: 40px;
  width: 90%;
  max-width: 440px;
  text-align: center;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  transform: scale(0.95);
  opacity: 0;
  animation: modalFadeIn 0.3s ease forwards;
}

@keyframes modalFadeIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-content h2 {
  font-family: 'Rubik', sans-serif;
  font-size: 72px;
  font-style: italic;
  background: linear-gradient(135deg, #02CEFA -10.14%, #2763FF 51.25%, #A515FD 107.69%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 24px;
  letter-spacing: -0.02em;
}

.modal-content p {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #344054;
  margin-bottom: 32px;
  line-height: 1.5;
}

.download-buttons {
  display: flex;
  justify-content: center;
}

.download-buttons .btn {
  background: linear-gradient(135deg, #2763FF 0%, #A515FD 100%);
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.download-buttons .btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: 0.5s;
}

.download-buttons .btn:hover::before {
  left: 100%;
}

.download-buttons .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

/* Background blur effect for images */
.event-post__image-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(100px);
  z-index: 1;
  opacity: 0.5;
}